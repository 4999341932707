import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { env } from 'next-runtime-env';

import { getCookies } from '~/app/actions';

const DEV_HOST = 'testsympee.ru';
const httProtocol = 'https';
const serviceHost = env('NEXT_PUBLIC_HOST') || DEV_HOST;

export const apiSlice = createApi({
    reducerPath: 'rtk-query',
    baseQuery: fetchBaseQuery({
        prepareHeaders: async (headers) => {
            let csrfToken = '';
            try {
                csrfToken = (await getCookies('token')) || '';
            } catch (e) {
                console.log('tokenErr', e);
            }

            headers.set('Authorization', `Bearer ${csrfToken}`);

            return headers;
        },
        baseUrl: `${httProtocol}://api.${serviceHost}/`,
    }),
    refetchOnReconnect: true,
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return action.payload[reducerPath];
        }
    },
    endpoints: () => ({}),
});
