'use client';

import { useEffect, useState } from 'react';
import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

import { useLazyGetDocumentsQuery } from '~/lib/documents/api';
import { getCookies } from '~/app/actions';

import { ButtonCore, TypographyCore } from '../core-components';

import styles from './cookie-accept.module.css';

export const CookiesAccept = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isOpenDesktopAccept, setOpenDesktopAccept] = useState(false);

    const [triggerGetDocuments] = useLazyGetDocumentsQuery();
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });
    const handleAcceptCookie = async () => {
        document.cookie = 'cp=accept; path=/; secure=false';

        onClose();
        setOpenDesktopAccept(false);
    };

    const handleOpenDocument = async () => {
        const results = await triggerGetDocuments();

        if (results.data) {
            window.open(results.data.data.personal_data);
        }
    };

    useEffect(() => {
        getCookies('cp').then((item) => {
            if (item) {
                onClose();
                setOpenDesktopAccept(false);
            } else {
                onOpen();
                setOpenDesktopAccept(true);
            }
        });
    }, [onClose, onOpen]);

    if (isMobile) {
        return (
            <Drawer placement='bottom' isOpen={isOpen} onClose={handleAcceptCookie} autoFocus={false} returnFocusOnClose={false}>
                <DrawerOverlay />
                <DrawerContent borderTopLeftRadius={'12px'} borderTopRightRadius={'12px'} border={'none'}>
                    <DrawerHeader />
                    <DrawerBody>
                        <TypographyCore view='medium' size='m' className='mb-16'>
                            Мы используем{' '}
                            <ButtonCore view='link' onClick={handleOpenDocument} className={styles['document-button']}>
                                cookies
                            </ButtonCore>
                            , чтобы сохранять ваш поиск, рекомендовать полезное и создавать другие удобства на сайте
                        </TypographyCore>
                        <ButtonCore view='accent' onClick={handleAcceptCookie} size='small'>
                            Окей
                        </ButtonCore>
                    </DrawerBody>
                    <DrawerFooter />
                </DrawerContent>
            </Drawer>
        );
    }

    return (
        <>
            {isOpenDesktopAccept ? (
                <Flex className={styles.container}>
                    <TypographyCore view='medium' size='m'>
                        Мы используем{' '}
                        <ButtonCore view='link' onClick={handleOpenDocument} className={styles['document-button']}>
                            cookies
                        </ButtonCore>
                        , чтобы сохранять ваш поиск, рекомендовать полезное и создавать другие удобства на сайте
                    </TypographyCore>
                    <ButtonCore
                        view='accent'
                        onClick={handleAcceptCookie}
                        size='small'
                        style={{
                            width: '8vw',
                            maxWidth: '100px',
                            marginLeft: '8px',
                        }}
                    >
                        Окей
                    </ButtonCore>
                </Flex>
            ) : null}
        </>
    );
};
