import { useDispatch, useSelector, useStore } from 'react-redux';
import type { AsyncThunk } from '@reduxjs/toolkit';

import type { AppDispatch, AppStore, RootState } from './store';
import { makeStore } from './store';

export type ApplicationDispatch = ReturnType<typeof makeStore>['dispatch'];

export type AppAsyncThunkConfig = {
    state: RootState;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();

type GenericAsyncThunk = AsyncThunk<unknown, unknown, AppAsyncThunkConfig>;

export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;
