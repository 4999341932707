import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"cyrillic\"]}],\"variableName\":\"interTight\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionWrapper"] */ "/app/src/app/SessionWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/app/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/cookies-accept/cookies-accept.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/yandex-metrika-pixel/yandex-metrika-pixel.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/yandex-metrika/yandex-metrika.tsx");
