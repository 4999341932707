import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

import type { ApplicationDispatch } from '~/lib/hooks';
import { handleApiResponseError } from '~/lib/notification/thunks';

export const rtkQueryErrorHandler: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action)) {
            const endpointName =
                typeof action?.meta?.arg === 'object' &&
                action.meta.arg &&
                'endpointName' in action.meta.arg &&
                typeof action.meta.arg.endpointName === 'string' &&
                action.meta.arg.endpointName;
            const title = `Произошла ошибка при вызове '${endpointName || 'Неопознанный вызов'}'`;

            const errorMessage =
                // Текст ошибки из rejectWithValue передается в action.payload
                typeof action.payload === 'string' ? action.payload : action.error.message;

            const errorFromPayload = action.payload && typeof action.payload === 'object' && 'error' in action.payload ? action.payload.error : '';

            const message = errorFromPayload ? errorFromPayload : errorMessage;

            (dispatch as ApplicationDispatch)(handleApiResponseError(`${title}: ${message}`, 'Неопознанная ошибка при вызове', 'Ошибка при вызове'));
        }

        return next(action);
    };
