import { combineReducers } from '@reduxjs/toolkit';

import cart from './cart/slice';
import notification from './notification/slice';
import product from './product/slice';
import { apiSlice } from './rtk-query/api';
import user from './user/slice';

export const rootReducer = combineReducers({
    cart,
    product,
    user,
    notification,
    [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
