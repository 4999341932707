'use client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { NotificationItem } from '~/components/notification/notification-item';

const theme = extendTheme({
    colors: {
        brand: {
            100: 'rgba(3, 77, 33, 1)',
            200: 'rgba(3, 77, 33, 1)',
            300: 'rgba(3, 77, 33, 1)',
            400: 'rgba(3, 77, 33, 1)',
            500: 'rgba(3, 77, 33, 1)',
            600: 'rgba(3, 77, 33, 1)',
            700: 'rgba(3, 77, 33, 1)',
        },
    },
});

export function Providers({ children }: { children: React.ReactNode }) {
    return (
        <ChakraProvider
            theme={theme}
            toastOptions={{
                defaultOptions: {
                    position: 'top-right',
                    duration: 7000,
                    isClosable: true,
                    render: ({ id, title, description, status, onClose }) => (
                        <NotificationItem id={id} title={title} description={description} status={status} onClose={onClose} />
                    ),
                },
            }}
        >
            {children}
        </ChakraProvider>
    );
}
