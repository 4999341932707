import { useEffect } from 'react';
import { CheckCircleIcon, CloseIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, ToastProps } from '@chakra-ui/react';

import { TypographyCore } from '~/components/core-components';

import { useAppDispatch } from '~/lib/hooks';
import { removeNotificationById } from '~/lib/notification/slice';

export const NotificationItem = ({ id, title, description, status, onClose }: ToastProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(removeNotificationById(id));
        };
    }, [dispatch, id]);

    return (
        <Box
            border='1px solid'
            borderColor={status === 'success' ? 'rgba(106, 153, 46, 1)' : 'rgba(202, 50, 20, 1)'}
            borderRadius={'12px'}
            backgroundColor={'rgba(255, 255, 255, 1)'}
            p='20px'
            position='relative'
        >
            <IconButton
                aria-label='Close toast'
                icon={<CloseIcon w='8px' h='8px' />}
                backgroundColor={'inherit'}
                position={'absolute'}
                onClick={() => {
                    dispatch(removeNotificationById(id));
                    onClose?.();
                }}
                w='16px'
                h='16px'
                right='2px'
                top='5px'
            />
            <Flex gap='8px'>
                <div>
                    {status === 'success' ? (
                        <CheckCircleIcon w='33px' h='33px' color='rgba(106, 153, 46, 1)' />
                    ) : (
                        <WarningIcon w='33px' h='33px' color='rgba(202, 50, 20, 1)' />
                    )}
                </div>
                <div>
                    <TypographyCore view='bold' size='s' className='mb-4'>
                        {title}
                    </TypographyCore>
                    <TypographyCore view='regular' size='s'>
                        {description}
                    </TypographyCore>
                </div>
            </Flex>
        </Box>
    );
};
