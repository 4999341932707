import { apiSlice } from '../rtk-query/api';

import type { Category, Product, ProductFull } from './types';

export const productsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query<{ data: { items: Product[] } }, { $filter?: string }>({
            query: ({ $filter }) => ({ url: `v1/products?${$filter}` }),
            transformErrorResponse: () => 'Возникла проблема при получении данных о товарах. Попробуйте еще раз',
        }),
        getProductsCategories: builder.query<{ data: { items: Category[] } }, { $filter?: string }>({
            query: ({ $filter }) => `v1/products/categories${$filter}`,
            transformErrorResponse: () => 'Возникла проблема при получении доступных категорий. Попробуйте еще раз',
        }),
        getProductById: builder.query<{ data: ProductFull }, { id: number }>({
            query: ({ id }) => `v1/products/${id}`,
            transformErrorResponse: () => 'Возникла проблема при получении информации о товаре. Попробуйте еще раз',
        }),
    }),
});

export const {
    useGetProductsCategoriesQuery,
    useGetProductsQuery,
    useGetProductByIdQuery,
    util: { getRunningQueriesThunk },
} = productsApi;
