import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { rtkQueryErrorHandler } from '~/lib/rtk-query/error-handler-middleware';

import { productsApi } from './product/api';
import { rootReducer } from './index';

export const makeStore = () => {
    return configureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (gDM) => gDM().concat(productsApi.middleware, rtkQueryErrorHandler),
    });
};

export const wrapper = createWrapper(makeStore);

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
