import { useEffect, useRef } from 'react';

/**
 * Хук для хранения предыдущих значений пропсов (переменных)
 */
export const usePrevious = <T>(value: T): T => {
    const ref = useRef<T>(value);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};
