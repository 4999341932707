import type { ThunkAction, UnknownAction } from '@reduxjs/toolkit';

import { addNotification } from '~/lib/notification/slice';
import { getErrorMessage } from '~/utils';

import type { RootState } from '../store';

export type ActionFunction<R = void> = ThunkAction<R, RootState, unknown, UnknownAction>;

export const handleApiResponseError =
    (error: unknown, fallbackError?: string, title = fallbackError): ActionFunction =>
    (dispatch) => {
        try {
            const text = getErrorMessage(error);

            dispatch(
                addNotification({
                    ...(text !== title && { title }),
                    status: 'error',
                    description: text || fallbackError,
                }),
            );

            // eslint-disable-next-line no-console
            console.error('Error:', error);
        } catch (e) {
            dispatch(
                addNotification({
                    status: 'error',
                    title: 'Не удалось выполнить действие',
                    description: 'Возникла ошибка при обработке ошибки от сервера',
                }),
            );
        }
    };
