import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = {
    search: '',
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setSearch: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
    },
});

export const { setSearch } = productSlice.actions;

export default productSlice.reducer;
