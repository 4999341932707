import { parsePhoneNumber } from 'libphonenumber-js';

/** Удалить элемент массива без мутации */
export function removeArrayItem<T>(array: T[], index: number): T[] {
    return [...array.slice(0, index), ...array.slice(index + 1)];
}

/** Изменить элемент массива без мутации */
export function updateArrayItem<T>(array: T[], index: number, item: T): T[] {
    return [...array.slice(0, index), item, ...array.slice(index + 1)];
}

const PHONE_NUMBER_LENGTH = 11;

export const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    const cleanPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');

    // производим преобразование телефона, когда длина cleanPhoneNumber больше или равна 11 (количество цифр в российских номерах)
    // иначе libPhoneNumber не принимает строку как номер телефона
    if (cleanPhoneNumber.length >= PHONE_NUMBER_LENGTH) {
        const phoneNumberUtil = parsePhoneNumber(phoneNumber, {
            defaultCountry: 'RU',
            defaultCallingCode: '+7',
        });

        const splitPhoneNumber = `7${phoneNumberUtil.nationalNumber}`;

        return splitPhoneNumber;
    }

    return cleanPhoneNumber;
};

export interface AxiosError extends Error {
    response?: { data?: { errorMessage?: string } };
    isAxiosError: boolean;
}

const isAxiosError = (error: unknown): error is AxiosError =>
    error !== null && typeof error === 'object' && 'isAxiosError' in error && error.isAxiosError === true;

const DEFAULT_ERROR_TEXT = 'Произошла ошибка';

export function getErrorMessage(error: unknown, fallbackError = DEFAULT_ERROR_TEXT) {
    let text = fallbackError;

    if (typeof error === 'string') {
        text = error;
    } else if (error && typeof error === 'object') {
        if (isAxiosError(error)) {
            text = error.response?.data?.errorMessage ?? error.message;
        } else if (error instanceof Error) {
            text = error.message;
        }
    }

    return text;
}

export type CopyTextArgs = {
    text: string;
    onSuccess?: () => void;
    onFail?: () => void;
};

export const copyTextToClipboard = async ({ text, onSuccess, onFail }: CopyTextArgs) => {
    try {
        // Clipboard есть во всех современных версиях браузеров, но может быть недоступен по 2 причинам:
        // 1. Пользователь не дал разрешение на использование своего буфера обмена
        // 2. Сайт использует незащищенный протокол (http вместо https)
        if (navigator?.clipboard?.writeText) {
            await navigator.clipboard.writeText(text);
            onSuccess?.();
        } else {
            onFail?.();
        }
    } catch (e) {
        onFail?.();
    }
};

export const isValidDate = (dateString: string) => {
    const dateRegex = /^(?:19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    if (!dateRegex.test(dateString)) return false;

    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

/**
 * Возвращает правильную форму для согласования с количественным числительным
 * @param n количественное числительное
 * @param forms формы существительного или словосочетания ([слово в им. падеже, в родительном падеже, слово во мн. числе])
 */
export function makeAgreeWithNumber(n: number, forms: [string, string, string]): string {
    const cases = [2, 0, 1, 1, 1, 2];

    return forms[n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]];
}
